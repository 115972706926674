import React, { FunctionComponent } from 'react';
import { ResponseInlineVideo } from '../responsive-video.component';

import sharedStyles from '../content-styles.module.scss';
import { QuoteCarousel } from '../../quote-carousel/quote-carousel.component';

const QUOTES = [
    'We have gathered a wealth of expertise in AI, making us industry leaders in football intelligence.',
    'Our AI solutions allow teams to process data faster and more effectively, freeing up time for coaches and staff to focus on game-winning decisions.',
];

export const AIAtTelemetry: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description} style={{ minHeight: 80 }}>
                At Telemetry Sports, we&apos;re pioneering the use of AI to redefine how football teams interpret and
                use data. With advanced computer vision and machine learning, we deliver game-changing insights that go
                beyond the stats. Our AI-driven solutions enable you to gain a deeper understanding of the game, turning
                data into a competitive advantage that helps you win where it matters most: in the margins.
            </div>
            <div className={sharedStyles.videoContainer}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_AIComputerVision_EDIT.mp4"
                    bordered
                />
            </div>
            <QuoteCarousel quotes={QUOTES} />
        </div>
    );
};
