import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';
import { QuoteCarousel } from '../../quote-carousel/quote-carousel.component';

const QUOTES = [
    'We are transforming vast amounts of data into actionable insights by detecting patterns invisible to the human eye.',
    'AI amplifies human capabilities by processing more data than any person could ever handle, making smarter, faster decisions.',
];

export const ComputerVision: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description}>
                Computer vision, a vital part of our AI suite, enables us to analyze game film and generate critical
                data like performance metrics. Though not predictive, computer vision processes video frames or still
                images to objectively assess player movements and tendencies, uncovering insights that would be
                impossible for the human eye to detect.
            </div>
            <div className={sharedStyles.videoContainer}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_ComputerVision_EDIT.mp4"
                    bordered
                />
            </div>
            <QuoteCarousel quotes={QUOTES} />
        </div>
    );
};
