import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';

export const AIChangingTheGame: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.videoContainer} style={{ marginBottom: 30 }}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_ChangingGame_EDIT.mp4"
                    bordered
                />
            </div>
            <div className={sharedStyles.description} style={{ minHeight: 150, flexDirection: 'column' }}>
                Telemetry Sports is at the forefront of using AI to revolutionize sports analytics. Our suite of
                AI-driven products—including solutions tailored for Pro, College, Recruit, and Broadcast—redefine how
                you engage with data. From uncovering hidden insights to making informed, data-backed decisions, AI is
                changing the game in:
                <ul>
                    <li>Enhancing process efficiency</li>
                    <li>Analyzing vast, complex data sets beyond human capacity</li>
                    <li>Accelerating decision-making to give your team a competitive edge</li>
                    <li>Enriching fan engagement with deeper insights</li>
                    <li>Proactively safeguarding player health through injury prevention insights</li>
                </ul>
            </div>
        </div>
    );
};
