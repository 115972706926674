import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';

export const MachineLearning: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.videoContainer} style={{ marginBottom: 30 }}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_MachineLearning_EDIT.mp4"
                    bordered
                />
            </div>
            <div className={sharedStyles.description} style={{ minHeight: 150, flexDirection: 'column' }}>
                Machine learning is the predictive powerhouse of our AI systems, enabling us to analyze player
                tendencies and game outcomes. Much like a coach learns from countless hours of game footage, our
                algorithms study vast amounts of data to identify patterns and predict the most likely outcomes. This
                allows us to:
                <ul>
                    <li>Make informed decisions based on real-time data</li>
                    <li>Identify trends across countless plays and players</li>
                    <li>Predict outcomes with greater accuracy, ensuring your team stays ahead of the competition</li>
                </ul>
            </div>
        </div>
    );
};
