import React, { FunctionComponent } from 'react';
import { Button } from 'semantic-ui-react';
import useMobile from 'src/shared/hooks/use-mobile';
import webStyles from './verticles.module.scss';
import mobileStyles from './verticles-mobile.module.scss';

import ReactGA from 'react-ga4';

interface VerticlesProps {
    style?: React.CSSProperties;
}

const Verticles: FunctionComponent<VerticlesProps> = ({ style }) => {
    const isMobile = useMobile();
    const styles = isMobile ? mobileStyles : webStyles;

    const recordEvent = (vertical: 'pro' | 'college' | 'recruit' | 'broadcast') => {
        ReactGA.event({
            category: 'Clicks',
            action: 'Vertical_Click',
            label: vertical,
        });
    };

    return (
        <div style={{ display: 'flex', width: '100%', zIndex: 2, justifyContent: 'center', ...style }}>
            <div className={styles.container}>
                <div className={styles.pillar}>
                    <div className={styles.heading} style={{ backgroundColor: '#e74b39' }}>
                        Pro
                    </div>
                    <div className={styles.content}>
                        You can trust us. Over 20 NFL organizations rely on our customized sports data technology to
                        inform decision-making and provide solutions to evolving team needs focused on winning results.
                    </div>
                    <a href="/pro" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button className={styles.learnBtn} onClick={() => recordEvent('pro')}>
                            Learn More
                        </Button>
                    </a>
                </div>
                <div className={styles.pillar}>
                    <div className={styles.heading} style={{ backgroundColor: '#3696d2' }}>
                        College
                    </div>
                    <div className={styles.content}>
                        We&apos;re your team&apos;s team. We act as a hands-on extension and support for NCAA football
                        organizations as they integrate curated sports data technology into everyday decision-making and
                        problem-solving.
                    </div>
                    <a href="/college" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button className={styles.learnBtn} onClick={() => recordEvent('college')}>
                            Learn More
                        </Button>
                    </a>
                </div>
                <div className={styles.pillar}>
                    <div className={styles.heading} style={{ backgroundColor: '#4ba123' }}>
                        Recruit
                    </div>
                    <div className={styles.content}>
                        Your big board becomes digital. The evaluation process gets streamlined allowing personnel to
                        efficiently customize player identity and evaluate player performance.
                    </div>
                    <a href="/recruit" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button className={styles.learnBtn} onClick={() => recordEvent('recruit')}>
                            Learn More
                        </Button>
                    </a>
                </div>
                <div className={styles.last}>
                    <div className={styles.heading} style={{ backgroundColor: '#ea9731' }}>
                        Broadcast
                    </div>
                    <div className={styles.content}>
                        Taking the game experience to another level. Our cutting-edge broadcast product is built to
                        enhance the football entertainment experience by providing audiences player performance data
                        within real-game context.
                    </div>
                    <a href="/broadcast" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button className={styles.learnBtn} onClick={() => recordEvent('broadcast')}>
                            Learn More
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Verticles;
