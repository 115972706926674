import React, { FunctionComponent, useMemo } from 'react';
import styles from './content-styles.module.scss';
import videoStyles from './responsive-video-styles.module.scss';
import ReactPlayer from 'react-player';
import { useElementSize } from '@mantine/hooks';

type VideoProps = {
    url: string;
    bordered?: boolean;
};

export const ResponseInlineVideo: FunctionComponent<VideoProps> = ({ url, bordered }) => {
    const { ref, width: containerWidth, height: containerHeight } = useElementSize();

    const videoDimensions = useMemo(() => {
        const RATIO = 1.77777;
        if (containerWidth / containerHeight > RATIO) {
            return { height: containerHeight, width: containerHeight * RATIO };
        }
        return { height: containerWidth / RATIO, width: containerWidth };
    }, [containerWidth, containerHeight]);

    return (
        <div className={styles.container} style={{ paddingTop: 20, paddingBottom: 20 }} ref={ref}>
            {bordered ? (
                <div
                    className={videoStyles.topEdge}
                    style={{
                        top: (containerHeight - videoDimensions.height) / 2,
                        left: (containerWidth - videoDimensions.width) / 2 - 20,
                    }}
                />
            ) : null}
            <ReactPlayer url={url} controls playsinline height={videoDimensions.height} width={videoDimensions.width} />
            {bordered ? (
                <div
                    className={videoStyles.bottomEdge}
                    style={{
                        bottom: (containerHeight - videoDimensions.height) / 2,
                        right: (containerWidth - videoDimensions.width) / 2 - 20,
                    }}
                />
            ) : null}
        </div>
    );
};
