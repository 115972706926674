import React, { FunctionComponent } from 'react';
import { Image } from 'semantic-ui-react';
import webStyles from './background.module.scss';
import mobileStyles from './background-mobile.module.scss';
import timeline from '../../../../assets/images/timeline.svg';
import verticalTimeline from '../../../../assets/images/timeline-mobile.svg';

import useMobile from 'src/shared/hooks/use-mobile';

const Background: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    return (
        <div className={styles.container}>
            <div className={styles.graphic}>
                <div className={styles.graphicHeader}>WHO WE ARE</div>
                <div>
                    <p>
                        Telemetry Sports is a trusted advisor to over 30 NFL and NCAA&reg; teams providing quality
                        sports data technology that produces efficient and winning results. Known for swift and reliable
                        response solutions—Telemetry Sports offers organizations hands-on support built under their own
                        unique banner.
                    </p>
                    <p>
                        We aren&apos;t “one size fits all” in our approach. We understand your organization is unique
                        with individual needs and goals. Telemetry Sports&apos; one-stop-shop for quality sports data
                        technology works as an essential extension of your processes allowing more time for informed
                        decision-making, practice planning, play and player performance evaluation, recruitment, and
                        overall game preparation.
                    </p>
                </div>
            </div>
            <Image src={mobile ? verticalTimeline : timeline} className={styles.timeline} />
        </div>
    );
};

export default Background;
