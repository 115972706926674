import React, { FunctionComponent } from 'react';
import { ResponseInlineVideo } from '../responsive-video.component';

import sharedStyles from '../content-styles.module.scss';

export const AIInSports: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description} style={{ minHeight: 80 }}>
                AI is reshaping the landscape of sports, driving smarter decisions, enhancing player performance, and
                elevating fan engagement. At Telemetry Sports, we&apos;re at the forefront of this revolution, using
                cutting-edge technology to transform how sports data is captured, analyzed, and leveraged.
            </div>
            <ResponseInlineVideo
                url={
                    'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_AIOverview_EDIT.mp4'
                }
            />
        </div>
    );
};
