import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';

export const WinsAboveReplacement: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description}>
                At Telemetry Sports, our commitment to predictive analytics goes beyond simple player statistics.
                We&apos;ve revolutionized the way we evaluate players with our proprietary Wins Above Replacement (WAR)
                metric. Our WAR metric is a game-changing tool that provides an unmatched, in-depth view of player
                performance, setting new standards for how teams can assess and maximize their talent.
            </div>
            <div className={sharedStyles.videoContainer}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_WAR_EDIT.mp4"
                    bordered
                />
            </div>
        </div>
    );
};
