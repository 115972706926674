import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';
import { QuoteCarousel } from '../../quote-carousel/quote-carousel.component';

const QUOTES = [
    'AI allows us to simulate and evaluate game scenarios, providing coaches and staff with the actionable metrics they need to make smarter decisions on and off the field.',
];

export const PlayerEvaluation: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description} style={{ minHeight: 85 }}>
                Telemetry Sports uses proprietary AI-driven metrics to transform player evaluation. Our unique tools not
                only analyze past performance but also predict how players will develop and excel at the next level.
                Whether you&apos;re scouting college talent or seeking hidden potential, our data-backed insights give
                you the edge to make informed decisions and stay ahead of your competition.
            </div>
            <div className={sharedStyles.videoContainer}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_PlayerEval_EDIT.mp4"
                    bordered
                />
            </div>
            <QuoteCarousel quotes={QUOTES} />
        </div>
    );
};
