import React, { FunctionComponent } from 'react';
import { Image } from 'semantic-ui-react';
import ContactUs from 'src/shared/components/contact/contact.component';
import VerticalHeader from 'src/shared/components/vertical-header/vertical-header.component';

import webStyles from './pro.module.scss';
import mobileStyles from './pro-mobile.module.scss';
import useMobile from 'src/shared/hooks/use-mobile';
import useWindowDimensions from 'src/shared/hooks/use-window-dimensions';
import footballDive from 'src/assets/images/players/football-dive.svg';
import { Parallax } from 'react-scroll-parallax';
import VideoComponent from 'src/shared/components/videoComponent/videoComponent';

const Pro: FunctionComponent = () => {
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    const { width } = useWindowDimensions();

    return (
        <div className={styles.container}>
            <VerticalHeader title="Pro" tagline="INSERT RELEVANT PRO TAGLINE/HEADER HERE" />
            <div className={styles.content}>
                <VideoComponent
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ts-pro.mp4"
                    styleClass={styles.video}
                />
                <div className={styles.descriptionContainer} style={{ width, maxWidth: width }}>
                    <div className={styles.description}>
                        <div className={styles.descriptionText}>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>Built just for you.</span> At
                                Telemetry Sports, we&apos;re good listeners. We hear our pro clients when they share
                                their unique needs. Our Pro product translates those needs into opportunities to
                                streamline efficiencies to maximize the ability to view data reliably and quickly. Our
                                ethical treatment of shared data technology seamlessly integrates into a custom-built
                                product offering teams customized solutions for reporting, evaluation, tracking, and
                                film.
                            </p>
                            <p>
                                <span style={{ fontWeight: 800, color: '#2e9ef2' }}>
                                    We&apos;re quick and reliable.{' '}
                                </span>
                                Trusted by over 20 NFL organizations, Telemetry Sports Pro is your search engine for all
                                things football. Search and organize large amounts of curated data with over 900 unique
                                tags to streamline the data gathering process.
                            </p>
                        </div>

                        <Parallax
                            className={styles.graphic}
                            translateX={[mobile ? 0 : -30, mobile ? 0 : -15]}
                            startScroll={-200}
                            endScroll={500}
                        >
                            <Image src={footballDive} />
                        </Parallax>
                    </div>
                </div>
            </div>
            <div className={styles.pillars}>
                <div>
                    <h2>flexible.</h2>
                    <p>
                        Every collaboration Telemetry Sports undertakes starts with reliability and the ability to
                        create customized data technology solutions and provide those solutions for professional
                        organizations under their own unique team banner. These solutions are built to be flexible and
                        respond to individual staff needs organization wide.
                    </p>
                </div>
                <div>
                    <h2>unique.</h2>
                    <p>
                        We’re listening. Telemetry Sports transitions the active-listening phase with clients into
                        production of on-demand, time-saving customized data technology solutions that will allow
                        professional organizations to stay informed, proactive decision-makers.
                    </p>
                </div>

                <div>
                    <h2>successful.</h2>
                    <p>
                        We’re partners for the long haul. Telemetry Sports is intent on supporting professional
                        organizational partners to aspire to and reach their own successes. The win-loss columns at the
                        end of the season tell a story but not the whole story. Telemetry Sports’ data technology
                        products can offer your organization the ability to get ahead in the game and make team goals
                        and winning aspirations reality.
                    </p>
                </div>
            </div>
            <ContactUs />
        </div>
    );
};

export default Pro;
