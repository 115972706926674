import React, { FunctionComponent } from 'react';
import styles from './quote-carousel-styles.module.scss';
import { Icon } from 'semantic-ui-react';

type QuoteCarouselProps = {
    quotes: string[];
};

export const QuoteCarousel: FunctionComponent<QuoteCarouselProps> = ({ quotes }) => {
    const [active, setActive] = React.useState(0);

    const onNavigate = (direction: 'next' | 'prev') => {
        const newVal = direction === 'next' ? active + 1 : active - 1;
        if (direction === 'next' && active < quotes.length - 1) {
            setActive((prev) => prev + 1);
            document
                .getElementById(quotes[newVal])
                ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        } else if (direction === 'prev' && active > 0) {
            setActive((prev) => prev - 1);
            document
                .getElementById(quotes[newVal])
                ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                marginTop: 20,
                position: 'relative',
                minHeight: 80,
            }}
        >
            <Icon
                name="chevron left"
                className={styles.arrow}
                onClick={() => onNavigate('prev')}
                style={{ opacity: quotes.length <= 1 ? 0 : 1 }}
            />
            <div style={{ position: 'absolute', zIndex: 1, top: 0, left: 30, opacity: 0.1 }}>
                <Icon name="quote left" size="huge" color="black" />
            </div>
            <div className={styles.content}>
                {quotes.map((quote) => (
                    <div key={quote} id={quote} className={styles.contentChild} style={{ width: '100%' }}>
                        {quote}
                    </div>
                ))}
            </div>
            <div style={{ position: 'absolute', zIndex: 1, top: 10, right: 30, opacity: 0.1 }}>
                <Icon name="quote right" size="huge" color="black" />
            </div>
            <Icon
                name="chevron right"
                className={styles.arrow}
                onClick={() => onNavigate('next')}
                style={{ opacity: quotes.length <= 1 ? 0 : 1 }}
            />
        </div>
    );
};
