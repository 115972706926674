import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';

export const AICompetitiveEdge: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description} style={{ minHeight: 80 }}>
                Telemetry Sports leverages AI to turn raw data into powerful insights, giving your organization the
                competitive edge. By automating data collection and transforming it into meaningful insights, we enable
                your team to process vast volumes of information with speed and precision, ensuring every decision is
                informed by the most advanced data available.
            </div>
            <div className={sharedStyles.videoContainer}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_EdgeOver_EDIT.mp4"
                    bordered
                />
            </div>
        </div>
    );
};
